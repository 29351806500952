import React from "react";
import { Button, Flex } from "antd";
import { useNavigate } from "react-router-dom";
import { ModifierOptionsColumn } from "../../../../columns/ModifierOptionsColumn";
import MyTable from "../../../UI/Table";
import { setModifierOptions } from "../../../../redux/Reducer";
import MyModal from "../../../UI/Modal";
import ModifierOptionsForm from "./ModifierOptionsForm";
import { translations } from "../../../../utils/translations";

const ModifierOptionsView = ({
  data,
  setPageNumber,
  pageSize,
  setPageSize,
  totalPages,
  item,
  setItem,
  onFinish,
  open,
  setOpen,
  user,
}) => {
  return (
    <Flex vertical>
      <Flex justify="space-between" align="center">
        <h2>{translations.modifierOptions}</h2>
        <Button type="primary" onClick={() => setOpen(true)}>
          {translations.addNew}
        </Button>
      </Flex>
      <br />
      <br />

      <MyTable
        data={data}
        setOpen={setOpen}
        setItem={setItem}
        modalFlag={true}
        rowKey="id"
        setPageNumber={setPageNumber}
        pageSize={pageSize}
        setPageSize={setPageSize}
        editPath="/modifier-options/edit/"
        basePath={`/modifier-options`}
        columns={ModifierOptionsColumn}
        setReduxAction={setModifierOptions}
        queryParams="id"
        totalPages={totalPages}
      />
      <br />
      <br />
      <MyModal setData={setItem} open={open} setOpen={setOpen}>
        <ModifierOptionsForm
          edit={item?.id}
          item={item}
          setItem={setItem}
          onFinish={onFinish}
          user={user}
        />
      </MyModal>
    </Flex>
  );
};

export default ModifierOptionsView;
