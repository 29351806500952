import React, { useState } from "react";
import {
  Collapse,
  Flex,
  Tree,
  message,
  Tag,
  Dropdown,
  Typography,
  Button,
  Select,
  Switch,
  Divider,
} from "antd"; // Import message from antd
import Info from "../store/shop/components/Info";
import {
  EditOutlined,
  FileAddOutlined,
  PlusOutlined,
  PlusSquareFilled,
} from "@ant-design/icons";
import ItemCategoryForm from "../store/shop/itemCategories/ItemCategoryForm";
import MyModal from "../UI/Modal";
import ItemForm from "../store/shop/items/ItemsForm";
import ModifierForm from "../store/shop/modifiers/ModifierForm";
import ModifierOptionsForm from "../store/shop/modifierOptions/ModifierOptionsForm";
import {
  setItemCategories,
  setItems,
  setModifierOptions,
  setModifiers,
} from "../../redux/Reducer";
import { useDispatch } from "react-redux";
import api from "../../utils/http";

const { Text } = Typography;
const TreeCompo = ({
  shopData,
  setShopData,
  data,
  onFinish,
  user,
  open,
  setOpen,
  selectedID,
  setSelectedID,
  add,
  setAdd,
  addData,
  setAddData,
  coupons,
  onActiveChange,
}) => {
  const dispatch = useDispatch();
  const { patch, put } = api();

  const [expandedKeys, setExpandedKeys] = useState([]);
  const [autoExpandParent, setAutoExpandParent] = useState(true);

  const onSelect = (selectedKeys, info) => {
    // Toggle expansion
    const key = info.node.key;
    const newExpandedKeys = expandedKeys.includes(key)
      ? expandedKeys.filter((k) => k !== key) // Collapse if already expanded
      : [...expandedKeys, key]; // Expand if not already expanded

    setExpandedKeys(newExpandedKeys);
    setAutoExpandParent(false); // Set to false to prevent auto-expand of all parent nodes
  };

  const onExpand = (expandedKeys) => {
    setExpandedKeys(expandedKeys);
    setAutoExpandParent(false); // Update expansion state
  };

  const onCheck = (checkedKeys, info) => {
    console.log("onCheck", checkedKeys, info);
  };

  const apiCallADD = {
    1: "/item-categories",
    2: "/items",
    3: "/modifiers",
    4: "/modifier-options",
  };

  const apiCallEdit = {
    1: "/item-categories/" + selectedID,
    2: "/items/" + selectedID,
    3: "/modifiers/" + selectedID,
    4: "/modifier-options/" + selectedID,
  };

  const onFinishLocal = () => {
    !selectedID
      ? dispatch(
          patch(apiCallADD[add], {
            ...addData,
            ...(addData.files && { files: addData.files.map((x) => x.id) }),
            ...(addData.price && { price: addData.price * 100 }),
            ...(addData.additionalTax && {
              additionalTax: addData.additionalTax * 100,
            }),
            language: user.language,
          })
            .then((response) => {
              setAddData({});
              setOpen(false);
            })
            .catch((error) => {
              message.error(error.response.data.message);
            })
        )
      : dispatch(
          put(apiCallEdit[add], {
            ...addData,
            ...(addData.files && { files: addData.files.map((x) => x.id) }),
            ...(addData.price && { price: addData.price * 100 }),
            ...(addData.modifiers && {
              modifiers: addData.modifiers.map((x) => x.id),
            }),
            ...(addData.additionalTax && {
              additionalTax: addData.additionalTax * 100,
            }),
            language: user.language,
          })
            .then((response) => {
              setAddData({});
              setOpen(false);
            })
            .catch((error) => {
              message.error(error.response.data.message);
            })
        );
    setOpen(false);
  };

  const modalComponent = {
    1: (
      <ItemCategoryForm
        item={addData}
        setItem={setAddData}
        onFinish={onFinishLocal}
      />
    ),
    2: (
      <ItemForm
        item={addData}
        setItem={setAddData}
        onFinish={onFinishLocal}
        user={user}
      />
    ),
    3: (
      <ModifierForm
        item={addData}
        setItem={setAddData}
        onFinish={onFinishLocal}
        user={user}
      />
    ),
    4: (
      <ModifierOptionsForm
        item={addData}
        setItem={setAddData}
        onFinish={onFinishLocal}
      />
    ),
  };

  return (
    <Flex vertical>
      <Collapse ghost>
        <Collapse.Panel header="My Shop" key="1">
          <Info
            setShopData={setShopData}
            shopData={shopData}
            user={user}
            onFinish={onFinish}
          />
        </Collapse.Panel>
        <Collapse.Panel header="Coupons" key="2">
          {coupons.map((coupon) => (
            <Flex vertical key={coupon.id}>
              <Flex justify="space-between">
                <Tag color={coupon.active ? "green" : "red"}>{coupon.code}</Tag>
                <Text>
                  Discount:{" "}
                  {`${coupon.discount} ${coupon.type === 0 ? "€" : "%"}`}
                </Text>
                <Text>Minimum Order: {coupon.minimumOrder}</Text>
                <Switch
                  checked={coupon.active}
                  onChange={() => onActiveChange(coupon.id)}
                />
              </Flex>
              <Divider />
            </Flex>
          ))}
        </Collapse.Panel>
        <Collapse.Panel header="Data" key="3">
          <Flex
            vertical
            justify="center"
            align="center"
            style={{ float: "right", width: "100%" }}>
            <Text>Add New</Text>
            <Select
              value={add}
              style={{ marginBottom: 12, width: 130 }}
              onChange={(value) => {
                setAdd(value);
                setOpen(true);
              }}
              options={[
                {
                  value: 1,
                  label: <Text>Item Category</Text>,
                },
                {
                  value: 2,
                  label: <Text>Item</Text>,
                },
                {
                  value: 3,
                  label: <Text>Modifier</Text>,
                },
                {
                  value: 4,
                  label: <Text>Modifier Option</Text>,
                },
              ]}
            />
          </Flex>
          <br />
          <br />
          <br />
          <br />
          <Tree
            onSelect={onSelect}
            onExpand={onExpand} // Handle manual expand via UI
            expandedKeys={expandedKeys} // Pass expanded keys to Tree
            autoExpandParent={autoExpandParent}
            onCheck={onCheck}
            treeData={data.map((item) => ({
              ...item,
              children: item.children.map((child) => ({
                ...child,
                modifiers: child.children.map((modifier) => ({
                  ...modifier,
                  options: modifier.children.map((option) => ({
                    ...option,
                    title: (
                      <Flex justify="space-between">
                        <Tag
                          style={{
                            minWidth: 150,
                            maxWidth: 150,
                            width: "100%",
                          }}
                          color="purple">
                          <Flex justify="space-between">
                            <span>{option.title}</span>
                            <span>{option.price}€</span>
                          </Flex>
                        </Tag>
                      </Flex>
                    ),
                  })),
                })),
              })),
            }))}
            showLine
          />
        </Collapse.Panel>
      </Collapse>
      <MyModal open={open} setOpen={setOpen} setData={setAdd}>
        {modalComponent[add]}
      </MyModal>
    </Flex>
  );
};

export default TreeCompo;
