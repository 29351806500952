import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setModifierOptions,
  setModifiers,
  setShops,
} from "../../../../redux/Reducer";
import api from "../../../../utils/http";
import ModifierView from "../../../../components/store/shop/modifiers/ModifierView";
import { message } from "antd";

const ModifiersHOC = () => {
  const { modifiers, user } = useSelector((state) => state);
  const { get, patch, put } = api();
  const dispatch = useDispatch();

  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [item, setItem] = useState();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    user?.shop &&
      !open &&
      get(`/modifiers`, {
        pageNumber,
        pageSize,
      })
        .then((response) => {
          response && dispatch(setModifiers(response.data.data));
          setTotalPages(response.data.totalPages);
        })
        .catch((err) => {
          message.error(err.response.data.message);
        });
  }, [pageNumber, pageSize, open, user]);

  const onFinish = () => {
    item.id
      ? put(`/modifiers/${item.id}`, {
          name: item.name,
          shop: user.shop,
          language: user.language,
          type: !!item.type ? 1 : 0,
          required: !!item.required,
          options: item.options?.[0].id
            ? item.options.map((option) => option.id)
            : item.options,
        })
          .then((response) => {
            setOpen(!open);
            message.success("Success!");
            setItem({});
          })
          .catch((err) => {
            message.error(err.response.data.message);
          })
      : patch(`/modifiers`, {
          name: item.name,
          shop: user.shop,
          language: user.language,
          type: !!item.type ? 1 : 0,
          required: !!item.required,
          options: item.options,
        })
          .then((response) => {
            setOpen(!open);
            setItem({});
            message.success("Success!");
          })
          .catch((err) => {
            message.error(err.response.data.message);
          });
  };

  return (
    <ModifierView
      data={modifiers}
      pageNumber={pageNumber}
      setPageNumber={setPageNumber}
      pageSize={pageSize}
      setPageSize={setPageSize}
      totalPages={totalPages}
      item={item}
      setItem={setItem}
      onFinish={onFinish}
      open={open}
      setOpen={setOpen}
      user={user}
    />
  );
};

export default ModifiersHOC;
