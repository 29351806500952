import { Flex, Input } from "antd";
import React from "react";
import Map from "./Map";
import { translations } from "../../../../utils/translations";

const { TextArea } = Input;

const Address = ({ shopData, setShopData }) => {
  return (
    <Flex vertical>
      <br />
      <h4>{translations.street}</h4>
      <Input
        value={shopData?.address?.street}
        style={{ marginRight: 6, width: "50svw" }}
        onChange={({ target: { value } }) => {
          setShopData({
            ...shopData,
            address: {
              ...shopData.address,
              street: value,
            },
          });
        }}
      />
      <br />
      <h4>{translations.streetNumber}</h4>
      <Input
        style={{ marginRight: 6, width: "50svw" }}
        value={shopData?.address?.streetNumber}
        onChange={({ target: { value } }) => {
          setShopData({
            ...shopData,
            address: {
              ...shopData.address,
              streetNumber: value,
            },
          });
        }}
      />
      <br />
      <h4>{translations.area}</h4>
      <Input
        style={{ marginRight: 6, width: "50svw" }}
        value={shopData?.address?.area}
        onChange={({ target: { value } }) => {
          setShopData({
            ...shopData,
            address: {
              ...shopData.address,
              area: value,
            },
          });
        }}
      />
      <br />
      <br />
      <h3>{translations.mapExplain}</h3>
      <h3>{translations.mapExplain2}</h3>

      <Map data={shopData} setData={setShopData} />
      <br />
      <h3>{translations.notes}</h3>
      <TextArea
        rows={4}
        placeholder="notes"
        style={{ width: 300 }}
        value={shopData?.address?.notes}
        onChange={({ target: { value } }) => {
          setShopData({
            ...shopData,
            address: {
              ...shopData.address,
              notes: value,
            },
          });
        }}
      />
      <br />
    </Flex>
  );
};

export default Address;
