import React, { useEffect, useState } from "react";
import { Button, Flex, Table, Input, Space, message, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import api from "../../../utils/http";
import { translations } from "../../../utils/translations";
import { setCurrentTab, setUser } from "../../../redux/Reducer";

const { Text } = Typography;

const MyTable = ({
  data,
  setOpen,
  modalFlag,
  columns,
  setPageNumber,
  pageSize,
  setPageSize,
  editPath,
  basePath,
  setReduxAction,
  setItem,
  totalPages,
  queryParams,
}) => {
  // TODO add each redux state for deleting specific not reloading the page

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state);
  const { get, del, deleteGlobal, getGlobal } = api();

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [searchText, setSearchText] = useState("");

  const onSelectChange = (newSelectedRowKeys, item) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const hasSelected = selectedRowKeys.length > 0;

  const onSearch = (value) => {
    setSearchText(value);
    applyFilters(value);
  };

  const applyFilters = (searchText) => {
    if (user?.shop) {
      get(basePath, {
        pageSize,
        pageNumber: 1,
        ...(searchText && { name: searchText }),
      })
        .then((res) => {
          res.data.data.length > 0
            ? dispatch(setReduxAction(res.data.data))
            : message.warning("No data found");
        })
        .catch((err) => {
          message.error(err.response?.data.message);
        });
    } else {
      getGlobal(basePath, {
        pageSize,
        pageNumber: 1,
        ...(searchText && { name: searchText }),
      })
        .then((res) => {
          res.data.data.length > 0
            ? dispatch(setReduxAction(res.data.data))
            : message.warning("No data found");
        })
        .catch((err) => {
          message.error(err.response?.data.message);
        });
    }
  };

  const rowClassName = (record, index) =>
    index % 2 === 1 ? "custom-table-row" : "";

  return (
    <Flex gap="middle" vertical>
      <Space style={{ marginBottom: 16 }}>
        <Input
          placeholder={translations.nameSearch}
          onSearch={onSearch}
          onChange={(e) => setSearchText(e.target.value)}
          value={searchText}
          style={{ width: 200 }}
        />

        <Button
          disabled={searchText.length < 3}
          type="primary"
          onClick={() => applyFilters(searchText)}>
          {translations.apply}
        </Button>
        <Button
          onClick={() => {
            setSearchText("");
            applyFilters(false);
          }}>
          {translations.reset}
        </Button>
      </Space>
      <div style={{ height: "2svh" }}>
        {selectedRowKeys.length > 0 && (
          <Flex justify="flex-start" align="center">
            <Button
              style={{ width: "100px", marginRight: 12 }}
              danger
              onClick={() => {
                const deleteFunction = modalFlag ? del : deleteGlobal;

                deleteFunction(basePath, selectedRowKeys, queryParams)
                  .then((res) => {
                    message.success(
                      selectedRowKeys.length > 1
                        ? `IDs : ${selectedRowKeys}` + translations.haveDel
                        : `ID : ${selectedRowKeys}` + translations.hasDel
                    );
                    setTimeout(() => {
                      const fetchFunction = modalFlag ? get : getGlobal;

                      fetchFunction(basePath, {
                        pageSize: 10,
                        pageNumber: 1,
                      })
                        .then((res) => {
                          res.data.data.length > 0
                            ? dispatch(setReduxAction(res.data.data))
                            : message.warning("No data found");
                        })
                        .catch((err) => {
                          message.error(err.response?.data.message);
                        });
                    }, 1600);
                  })
                  .catch((err) => {
                    message.error(err.response?.data.message);
                  });
              }}>
              {translations.delete}
            </Button>

            <Text>
              {hasSelected
                ? translations.selected +
                  ` ${selectedRowKeys.length} ` +
                  translations.items
                : null}
            </Text>
          </Flex>
        )}
      </div>
      <Table
        rowKey="id"
        rowSelection={rowSelection}
        rowClassName={rowClassName}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              if (modalFlag) {
                setOpen(true);
                setItem(record);
              } else {
                dispatch(setUser({ ...user, shop: record.id }));
                dispatch(setCurrentTab("1"));
                navigate(editPath + record.id);
              }
            },
          };
        }}
        columns={columns}
        dataSource={data}
        pagination={{
          total: totalPages * pageSize,
          pageSize,
          showQuickJumper: true,
          responsive: true,

          onChange: (pg, pgSize) => {
            setPageNumber(pg);
            setPageSize(pgSize);
          },
        }}
      />
    </Flex>
  );
};

export default MyTable;
