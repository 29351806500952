export const translations = {
  email: "Email",
  password: "Κωδικός",
  submit: "Υποβολή",
  cancel: "Ακύρωση",
  hello: "Γειά σου, ",
  home: "Αρχική",
  users: "Χρήστες",
  shops: "Καταστήματα",
  cuisines: "Κουζίνες",
  languages: "Γλώσσες",
  cities: "Πόλεις",
  changeShop: "Αλλαγή Καταστήματος",
  nameSearch: "Αναζήτηση ονόματος",
  apply: "Εφαρμογή",
  reset: "Επαναφορά",
  selected: "Επιλεγμένα",
  items: "αντικείμενα ",
  delete: "Διαγραφή",
  addUser: "Προσθήκη Χρήστη",
  editUser: "Επεξεργασία Χρήστη",
  addShop: "Προσθήκη Καταστήματος",
  addCuisine: "Προσθήκη Κουζίνας",
  editCuisine: "Επεξεργασία Κουζίνας",
  addLanguage: "Προσθήκη Γλώσσας",
  addCity: "Προσθήκη Πόλης",
  editCity: "Επεξεργασία Πόλης",
  addNew: "Νέα Προσθήκη",
  ID: "ID",
  name: "Όνομα",
  page: "Σελίδα",
  firstName: "Όνομα",
  lastName: "Επώνυμο",
  phone: "Τηλέφωνο",
  code: "Κωδικός",
  activateLightMode: "Ενεργοποίηση Φωτεινού Τρόπου",
  activateDarkMode: "Ενεργοποίηση Σκοτεινού Τρόπου",
  logout: "Αποσύνδεση",
  login: "Σύνδεση",
  saveShop: "Αποθήκευση Καταστήματος",
  schedule: "Πρόγραμμα",
  address: "Διεύθυνση",
  street: "Οδός",
  streetNumber: "Αριθμός",
  postalCode: "Ταχυδρομικός Κώδικας",
  area: "Περιοχή",
  mapExplain:
    "Κάντε κλικ στον χάρτη για να επιλέξετε την τοποθεσία του καταστήματος",
  mapExplain2:
    "Επειτα ζωγραφίστε ένα πολύγωνο για να καθορίσετε την περιοχή παράδοσης",
  notes: "Σημειώσεις",
  gallery: "Γκαλερί",
  logo: "Λογότυπο",
  cover: "Εξώφυλλο",
  main: "Κύρια",
  upload: "Ανέβασμα Αρχείου",
  adminInfo: "Πληροφορίες Διαχειριστή",
  itemCatName: "Όνομα Κατηγορίας",
  description: "Περιγραφή",
  price: "Τιμή",
  available: "Διαθέσιμο",
  modifiers: " Γκρουπ Υλικών",
  modifier: "Γκρουπ Υλικών",
  modfierName: "Όνομα Υλικού",
  type: "Τύπος",
  radioButton: "Μια Επιλογή",
  checkbox: "Πολλές Επιλογές",
  required: "Υποχρεωτικό",
  yes: "Ναι",
  no: "Όχι",
  options: "Επιλογές",
  modifierOptions: "Υλικά",
  modifierOption: "Υλικό",
  additionalTax: "Επιπλέον Φόρος",
  image: "Εικόνα",
  passwordError: "Παρακαλώ συμπληρώστε τον κωδικό",
  emailError: "Παρακαλώ συμπληρώστε το email",
  haveDel: " διαγράφτηκαν",
  hasDel: " διαγράφτηκε",
  selected: "Επιλεχθηκαν",
  info: "Πληροφορίες",
  categories: "Κατηγορίες",
  items: "Προϊόντα",
  category: "Κατηγορία",
  item: "Προϊόν",
};
