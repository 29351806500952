import { Button, Flex, Input, Typography } from "antd";
import React, { useEffect, useState, useCallback } from "react";
import {
  GoogleMap,
  MarkerF,
  Circle,
  DrawingManager,
} from "@react-google-maps/api";
import { geocodeByAddress } from "react-google-places-autocomplete";
import { translations } from "../../utils/translations";
import { useNavigate } from "react-router-dom";

const { TextArea } = Input;
const { Text } = Typography;

const defaultLat = 37.9838;
const defaultLng = 23.7275;

const CityForm = ({ cityData, setCityData, onFinish }) => {
  const navigate = useNavigate();
  const [marker, setMarker] = useState({
    position: {
      lat: defaultLat,
      lng: defaultLng,
    },
    label: { color: "white", text: "O" },
  });
  const [circle, setCircle] = useState(null); // Circle data state
  const [mapLoaded, setMapLoaded] = useState(false);

  const containerStyle = {
    width: "80svw",
    height: "80svh",
  };

  const loadGoogleMapsScript = (callback) => {
    if (typeof window.google === "undefined") {
      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyDNMk6ZJBxML-0DWoyO-IRyuy44px0mXBY&libraries=places,drawing`;
      script.async = true;
      script.defer = true;
      script.onload = callback;
      document.head.appendChild(script);
    } else {
      callback();
    }
  };

  useEffect(() => {
    loadGoogleMapsScript(() => setMapLoaded(true));
  }, []);

  useEffect(() => {
    if (cityData?.lat && cityData?.lng) {
      setMarker((prev) => ({
        ...prev,
        position: { lat: cityData.lat, lng: cityData.lng },
      }));
      setCircle({
        center: { lat: cityData.lat, lng: cityData.lng },
        radius: cityData.radius,
      });
    }
  }, [cityData]);

  const geocodeAddress = useCallback((address) => {
    return geocodeByAddress(address).then((results) => {
      if (results.length > 0) {
        const location = results[0].geometry.location;
        const lat = location.lat();
        const lng = location.lng();

        return { lat, lng };
      }
      throw new Error("No geocoding results found.");
    });
  }, []);

  const mapClicked = useCallback(
    async (event) => {
      const lat = event.latLng.lat();
      const lng = event.latLng.lng();

      if (lat !== marker.position.lat || lng !== marker.position.lng) {
        setCityData((prev) => ({
          ...prev,
          lat,
          lng,
        }));
        setMarker((prev) => ({
          ...prev,
          position: { lat, lng },
        }));
      }
    },
    [setCityData, marker.position]
  );

  const onCircleComplete = useCallback(
    (circleObj) => {
      const center = circleObj.getCenter();
      const radius = circleObj.getRadius();

      setCityData((prev) => ({
        ...prev,
        radius: radius,
        lat: center.lat(),
        lng: center.lng(),
      }));

      setCircle({
        center: {
          lat: center.lat(),
          lng: center.lng(),
        },
        radius: radius,
      });

      circleObj.setMap(null); // Remove the drawn circle from the map
    },
    [setCityData]
  );

  // Function to handle geocoding based on street, streetNumber, and area
  const handleAddressGeocoding = useCallback(() => {
    const { street, streetNumber, area } = cityData;
    if (street && streetNumber && area) {
      const addressString = `${street} ${streetNumber}, ${area}`;
      geocodeAddress(addressString)
        ?.then(({ lat, lng }) => {
          setCityData((prev) => ({
            ...prev,
            lat,
            lng,
          }));
          setMarker((prev) => ({
            ...prev,
            position: { lat, lng },
          }));
        })
        .catch((error) => {
          console.error("Error in geocoding: ", error);
        });
    }
  }, [geocodeAddress, cityData, setCityData]);

  return (
    <Flex vertical>
      <br />
      <Flex>
        <Button
          style={{ width: 150, marginRight: 5 }}
          onClick={() => navigate(-1)}>
          Cancel{" "}
        </Button>
        <Button style={{ width: 150 }} type="primary" onClick={onFinish}>
          Save City
        </Button>
      </Flex>
      <br />
      <br />
      <Text>{translations.name}</Text>
      <Input
        value={cityData?.name || ""}
        style={{ marginRight: 6, width: "50svw" }}
        onChange={({ target: { value } }) => {
          setCityData({
            ...cityData,
            name: value,
          });
        }}
      />
      <br />
      <Text>{translations.street}</Text>
      <Input
        value={cityData?.street || ""}
        style={{ marginRight: 6, width: "50svw" }}
        onChange={({ target: { value } }) => {
          setCityData({
            ...cityData,
            street: value,
          });
        }}
        onBlur={handleAddressGeocoding} // Trigger geocoding when user leaves input field
      />
      <br />
      <Text>{translations.streetNumber}</Text>
      <Input
        value={cityData?.streetNumber || ""}
        style={{ marginRight: 6, width: "50svw" }}
        onChange={({ target: { value } }) => {
          setCityData({
            ...cityData,
            streetNumber: value,
          });
        }}
        onBlur={handleAddressGeocoding} // Trigger geocoding when user leaves input field
      />
      <br />
      <Text>{translations.area}</Text>
      <Input
        value={cityData?.area || ""}
        style={{ marginRight: 6, width: "50svw" }}
        onChange={({ target: { value } }) => {
          setCityData({
            ...cityData,
            area: value,
          });
        }}
        onBlur={handleAddressGeocoding} // Trigger geocoding when user leaves input field
      />
      <br />
      <br />

      {mapLoaded ? (
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={marker.position}
          zoom={10}
          onClick={mapClicked}>
          <MarkerF
            position={marker.position}
            label={marker.label}
            draggable
            onDragEnd={(event) => {
              const lat = event.latLng.lat();
              const lng = event.latLng.lng();
              setCityData((prev) => ({
                ...prev,
                lat,
                lng,
              }));
              setMarker((prev) => ({
                ...prev,
                position: { lat, lng },
              }));
            }}
          />
          {circle && (
            <Circle
              center={{ lat: cityData.lat, lng: cityData.lng }}
              radius={cityData.radius}
              options={{
                fillColor: "#FF0000",
                fillOpacity: 0.2,
                strokeWeight: 1,
              }}
            />
          )}

          {window.google && window.google.maps && (
            <DrawingManager
              onCircleComplete={onCircleComplete}
              options={{
                drawingControl: true,
                drawingControlOptions: {
                  position: window.google.maps.ControlPosition.TOP_CENTER,
                  drawingModes: [window.google.maps.drawing.OverlayType.CIRCLE],
                },
                circleOptions: {
                  fillOpacity: 0.3,
                  strokeWeight: 1,
                  clickable: true,
                  editable: true,
                  zIndex: 1,
                },
              }}
            />
          )}
        </GoogleMap>
      ) : (
        <div>Loading Map...</div>
      )}
    </Flex>
  );
};

export default CityForm;
