import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Flex, Select, Spin, Switch, Typography } from "antd";
import LiveOrders from "./store/LiveOrders";
import api from "../utils/http";
import { setShops } from "../redux/Reducer";
import { useNavigate } from "react-router-dom";

const { Title } = Typography;

const Home = () => {
  const { user } = useSelector((state) => state);
  const { innerWidth, innerHeight } = window;

  const { getGlobal } = api();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { shops } = useSelector((state) => state);

  return !user.id ? (
    <Flex justify="center" align="center" style={{ height: "10vh" }}>
      <Spin />
    </Flex>
  ) : (
    <div style={{ padding: 24 }}>
      {/* <span>{JSON.stringify(print)}</span> */}
      <span style={{ color: "yellow" }}>{window && "window"}</span>
      <span style={{ color: "yellow" }}>{window?.print && "window.print"}</span>
      {/* <span style={{color: 'yellow'}}>{window?. && 'window'}</span> */}

      {user && user?.role === 0 ? (
        <Flex justify="space-between" align="center" style={{ height: "10vh" }}>
          <Title>Hello Big Kota Channel</Title>
        </Flex>
      ) : (
        <div>
          <Flex justify="space-between" align="center">
            <Title>Live Orders</Title>
            <span style={{ color: "lightblue" }}>
              width: {innerWidth} <br />
            </span>
            <span style={{ color: "lightblue" }}>
              height: {innerHeight} <br />
            </span>
            <Switch
              checkedChildren={"Active"}
              unCheckedChildren="Inactive"
              defaultChecked={true}
              onChange={(value) => console.log({ value })}
            />
          </Flex>
          <LiveOrders />
        </div>
      )}
    </div>
  );
};

export default Home;
