import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import api from "../../utils/http";
import TreeCompo from "../../components/tree";
import { Flex, message, Tag, Typography } from "antd";
import {
  setCoupons,
  setItemCategories,
  setItems,
  setModifierOptions,
  setModifiers,
} from "../../redux/Reducer";
import { EditOutlined } from "@ant-design/icons";
const { Text } = Typography;

const TreeHOC = () => {
  const dispatch = useDispatch();
  const { user, itemCategories, items, modifiers, modifierOptions, coupons } =
    useSelector((state) => state);
  const { get, patch } = api();

  const [open, setOpen] = useState(false);
  const [selectedID, setSelectedID] = useState(null);
  const [add, setAdd] = useState(null);
  const [addData, setAddData] = useState(null);

  const [treeData, setTreeData] = useState([]);
  const [shopData, setShopData] = useState({
    address: {},
    name: "",
    phone: "",
    schedule: [
      ["08:00", "22:00"],
      ["08:00", "22:00"],
      ["08:00", "22:00"],
      ["08:00", "22:00"],
      ["08:00", "22:00"],
      ["08:00", "22:00"],
      ["08:00", "22:00"],
    ],
  });

  // Filters for pagination
  const [itemCatFilters, setItemCatFilters] = useState({
    pageNumber: 1,
    pageSize: 30,
  });
  const [itemFilters, setItemFilters] = useState({
    pageNumber: 1,
    pageSize: 30,
  });
  const [modifierFilters, setModifierFilters] = useState({
    pageNumber: 1,
    pageSize: 30,
  });
  const [modifierOptionFilters, setModifierOptionFilters] = useState({
    pageNumber: 1,
    pageSize: 30,
  });

  const getRandomColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  useEffect(() => {
    get("")
      .then((response) =>
        setShopData({
          ...response.data,
          schedule: response.data.schedule.map((item) =>
            item.length === 0 ? ["0000", "0000"] : item
          ),
          areas: response.data.areas.map((item) => ({
            ...item,
            fillColor: getRandomColor(),
          })),
        })
      )
      .catch((error) => message.error(error.response.data.message));

    get("/coupons", {
      pageNumber: 1,
      pageSize: 30,
    })
      .then((response) => {
        response && dispatch(setCoupons(response.data.data));
        setTotalPages(response.data.totalPages);
      })
      .catch((err) => {
        message.error(err.response.data.message);
      });
  }, []);

  useEffect(() => {
    !open && setSelectedID(null);
    !open && setAddData(null);
    !open && setAdd(null);
  }, [open]);

  // Fetch data for item categories
  useEffect(() => {
    !open &&
      get("/item-categories", itemCatFilters)
        .then((response) => {
          dispatch(setItemCategories(response.data));
        })
        .catch((error) => message.error(error.response?.data?.message));
  }, [itemCatFilters, open]);

  // Fetch data for items
  useEffect(() => {
    !open &&
      get("/items", itemFilters)
        .then((response) => {
          dispatch(setItems(response.data));
        })
        .catch((error) => message.error(error.response?.data?.message));
  }, [itemFilters, open]);

  // Fetch data for modifiers
  useEffect(() => {
    !open &&
      get("/modifiers", modifierFilters)
        .then((response) => {
          dispatch(setModifiers(response.data));
        })
        .catch((error) => message.error(error.response?.data?.message));
  }, [modifierFilters, open]);

  // Fetch data for modifier options
  useEffect(() => {
    !open &&
      get("/modifier-options", modifierOptionFilters)
        .then((response) => {
          dispatch(setModifierOptions(response.data));
        })
        .catch((error) => message.error(error.response?.data?.message));
  }, [modifierOptionFilters, open]);

  useEffect(() => {
    if (
      itemCategories.data?.length > 0 &&
      items.data?.length > 0 &&
      modifiers.data?.length > 0 &&
      modifierOptions.data?.length > 0
    ) {
      const newTreeData = buildTreeData(
        itemCategories.data,
        items.data,
        modifiers.data,
        modifierOptions.data
      );

      setTreeData(newTreeData);
    }
  }, [itemCategories, items, modifiers, modifierOptions]);

  const onInfoUpdate = () => {
    patch("", {
      ...shopData,
      user: {
        ...shopData.user,
        language: user.language,
      },
      schedule: shopData.schedule.map((day) => {
        if (day[0] === "0000" && day[1] === "0000") {
          return [];
        }
        return day.map((time) => time.replace(":", ""));
      }),
    })
      .then(() => {
        message.success("Shop updated successfully");
      })
      .catch((err) => {
        message.error(err.response.data.message);
      });
  };

  const onActiveChange = (id) => {
    patch(`/coupons/status?id=${id}`)
      .then((response) => {
        message.success("Success!");
        dispatch(
          setCoupons(
            coupons.map((coupon) =>
              coupon.id === id ? { ...coupon, active: !coupon.active } : coupon
            )
          )
        );
      })
      .catch((err) => {
        message.error(err.response.data.message);
      });
  };

  // Infinite scroll logic for categories, items, modifiers, and modifier options
  const handleInfiniteScroll = (type) => {
    if (type === "itemCategories") {
      setItemCatFilters((prev) => ({
        ...prev,
        pageNumber: prev.pageNumber + 1,
      }));
    } else if (type === "items") {
      setItemFilters((prev) => ({
        ...prev,
        pageNumber: prev.pageNumber + 1,
      }));
    } else if (type === "modifiers") {
      setModifierFilters((prev) => ({
        ...prev,
        pageNumber: prev.pageNumber + 1,
      }));
    } else if (type === "modifierOptions") {
      setModifierOptionFilters((prev) => ({
        ...prev,
        pageNumber: prev.pageNumber + 1,
      }));
    }
  };

  // Helper function to build the hierarchical structure for tree
  const buildTreeData = (categories, items, modifiers, modifierOptions) => {
    return categories.map((category) => ({
      title: (
        <Tag style={{ maxWidth: 200, width: "100%" }} color="blue">
          {category.name}
        </Tag>
      ),
      key: `category-${category.id}`,
      children: items
        .filter((item) => item.category === category.id)
        .map((item) => ({
          title: (
            <Flex justify="space-between">
              <Tag
                style={{ minWidth: 200, maxWidth: 200, width: "100%" }}
                color="green">
                <Flex justify="space-between">
                  <span>{item.name}</span>
                  <span>{item.price}€</span>
                </Flex>
              </Tag>
              <EditOutlined
                onClick={() => {
                  setAdd(2);
                  setOpen(true);
                  setSelectedID(item.id);
                  setAddData(item);
                }}
              />
            </Flex>
          ),
          key: `item-${item.id}`,
          children: item.modifiers.map((modifier) => ({
            title: (
              <Flex justify="space-between">
                <Tag style={{ maxWidth: 200, width: "100%" }} color="red">
                  {modifier.name}
                </Tag>
                <EditOutlined
                  onClick={() => {
                    setAdd(3);
                    setOpen(true);
                    setSelectedID(modifier.id);
                    setAddData(modifier);
                  }}
                />
              </Flex>
            ),
            key: `modifier-${modifier.id}`,
            children: modifier.options.map((modifierOption) => ({
              title: (
                <Flex justify="space-between">
                  <Tag
                    style={{ minWidth: 150, maxWidth: 150, width: "100%" }}
                    color="purple">
                    <Flex justify="space-between">
                      <span>{modifierOption.name}</span>
                      <span>{modifierOption.price}€</span>
                    </Flex>
                  </Tag>
                  <EditOutlined
                    onClick={() => {
                      setAdd(4);
                      setOpen(true);
                      setSelectedID(modifierOption.id);
                      setAddData(modifierOption);
                    }}
                  />{" "}
                  {/* Pass option ID to the message */}
                </Flex>
              ),
              key: `modifierOption-${modifierOption.id}`,
            })),
          })),
        })),
    }));
  };

  return (
    <TreeCompo
      setShopData={setShopData}
      shopData={shopData}
      data={treeData}
      setData={setTreeData}
      user={user}
      onFinish={onInfoUpdate}
      onScroll={handleInfiniteScroll} // Pass infinite scroll handler
      open={open}
      setOpen={setOpen}
      selectedID={selectedID}
      setSelectedID={setSelectedID}
      add={add}
      setAdd={setAdd}
      addData={addData}
      setAddData={setAddData}
      coupons={coupons}
      onActiveChange={onActiveChange}
    />
  );
};

export default TreeHOC;
