import React from "react";
import { Button, Flex, Input, Switch, Table, Typography } from "antd";
import MyTable from "../../../UI/Table";
import { setCoupons } from "../../../../redux/Reducer";
import MyModal from "../../../UI/Modal";
import { CouponsColumns } from "../../../../columns/CouponsColumns";

const { Text } = Typography;

const CouponsTable = ({
  data,
  setPageNumber,
  pageSize,
  setPageSize,
  totalPages,
  item,
  setItem,
  onFinish,
  open,
  setOpen,
  user,
  onActiveChange,
}) => {
  return (
    <Flex vertical>
      <Flex justify="space-between" align="center">
        <h2>Coupons</h2>
        <Button type="primary" onClick={() => setOpen(true)}>
          Add New
        </Button>
      </Flex>

      <Table dataSource={data} columns={CouponsColumns(onActiveChange)} />

      <br />
      <br />
      <MyModal setData={setItem} open={open} setOpen={setOpen}>
        <h2>{item?.id ? "Edit Coupon" : "Add New Coupon"}</h2>
        <Flex vertical>
          <Text>Minimum Order </Text>
          <Input
            value={item?.minimumOrder}
            onChange={({ target: { value } }) => {
              setItem({
                ...item,
                minimumOrder: value,
              });
            }}
            placeholder="Minimum Order"
            style={{
              marginRight: 12,
              marginBottom: 12,
            }}
          />
        </Flex>

        <Flex vertical>
          <Text>Type </Text>
          <Switch
            style={{ maxWidth: 100 }}
            checkedChildren="Percentage"
            unCheckedChildren="Fixed"
            checked={item?.type}
            onChange={() => {
              setItem({
                ...item,
                type: !item?.type,
              });
            }}
          />
        </Flex>

        <Flex vertical>
          <Text>Discount (€)</Text>
          <Input
            autoFocus
            value={item?.discount}
            onChange={({ target: { value } }) => {
              setItem({
                ...item,
                discount: value,
              });
            }}
            placeholder="Discount"
            style={{
              marginRight: 12,
              marginBottom: 12,
            }}
          />
        </Flex>

        <Button onClick={onFinish} style={{ width: 100 }}>
          Save
        </Button>
      </MyModal>
    </Flex>
  );
};

export default CouponsTable;
